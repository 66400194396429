import React, { useContext, useState } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
//functions
import {
    _t,
    getCookie,
    customStyle
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import ProgressBarModal from "../../pos/newpos/ItemModal/ProgressBarModal";

//pages & includes
import ManageSidebar from "../ManageSidebar";

const ClosingStock = () => {
    const { t } = useTranslation();

    const { branchForSearch } = useContext(RestaurantContext);
    
    const [startDate, setStartDate] = useState(null);
    const [fromEndDate, setFromEndDate] = useState(null);
    const [closingStock, setClosingStock] = useState(null);
    const [loading, setLoading] = useState(false);
   
    const [progress, setProgress] = useState(0);

    const handleBranch = (branch) => {
      setClosingStock({
        ...closingStock,
        branch,
      });
    };

    //get closing stock
    const getClosingStock = () => {
      // console.log("closingStock?.branch?.id ", closingStock?.branch?.id);
      if (startDate !== null && fromEndDate !== null) {
  
        let toDate = moment(startDate).format("YYYY-MM-DD");
        let fromDate = moment(fromEndDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/month-closing-inventory-stock";
  
        let formData = new FormData();
        formData.append("to_date", toDate);
        formData.append("from_date", fromDate);
        formData.append("branch_id", closingStock?.branch?.id ? closingStock?.branch?.id : "");
        setLoading(true);
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
            onUploadProgress: (event) => {
                if (event.total) {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    setProgress(percentCompleted);
                }
            },
          })
          .then((res) => {
           
            setClosingStock(null);
            toast.success(`${_t(t("Stock closing has been successful"))}`,{
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            })
          })
          .catch((error) => {
            console.log("abcd44",error.response.data);
            if(error.response.data.status === "422"){
              toast.error(
                `${_t(t(error.response.data.message))}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
            }else{
              toast.error(
                `${_t(t("Failed to load data"))}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
            }
          }).finally(()=>{
            setLoading(false);
            setProgress(0);
        });
      } else {
        toast.error(
          `${_t(t("Please select dates"))}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

     //get production stock
     const getProductionStock = () => {
      // console.log("closingStock?.branch?.id ", closingStock?.branch?.id);
      if (startDate !== null && fromEndDate !== null) {
  
        let toDate = moment(startDate).format("YYYY-MM-DD");
        let fromDate = moment(fromEndDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/month-closing-production-update-stock";
  
        let formData = new FormData();
        formData.append("to_date", toDate);
        formData.append("from_date", fromDate);
        formData.append("branch_id", closingStock?.branch?.id ? closingStock?.branch?.id : "");
        setLoading(true);
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
            onUploadProgress: (event) => {
                if (event.total) {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    setProgress(percentCompleted);
                }
            },
          })
          .then((res) => {
           
            setClosingStock(null);
            toast.success(`${_t(t("Production closing has been successful"))}`,{
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            })
          })
          .catch((error) => {
            if(error.response.data.status === "422"){
              toast.error(
                `${_t(t(error.response.data.message))}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
            }else{
              toast.error(
                `${_t(t("Failed to load data"))}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
            }
          }).finally(()=>{
            setLoading(false);
            setProgress(0);
        });
      } else {
        toast.error(
          `${_t(t("Please select dates"))}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

     //get costing
     const getCostingStock = () => {
      // console.log("closingStock?.branch?.id ", closingStock?.branch?.id);
      if (startDate !== null && fromEndDate !== null) {
  
        let toDate = moment(startDate).format("YYYY-MM-DD");
        let fromDate = moment(fromEndDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/month-closing-food-cost-stock";
  
        let formData = new FormData();
        formData.append("to_date", toDate);
        formData.append("from_date", fromDate);
        formData.append("branch_id", closingStock?.branch?.id ? closingStock?.branch?.id : "");
        setLoading(true);
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
            onUploadProgress: (event) => {
                if (event.total) {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    setProgress(percentCompleted);
                }
            },
          })
          .then((res) => {
           
            setClosingStock(null);
            toast.success(`${_t(t("Costing stock has been successful"))}`,{
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            })
          })
          .catch((error) => {
            if(error.response.data.status === "422"){
              toast.error(
                `${_t(t(error.response.data.message))}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
            }else{
              toast.error(
                `${_t(t("Failed to load data"))}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
            }
          }).finally(()=>{
            setLoading(false);
            setProgress(0);
        });
      } else {
        toast.error(
          `${_t(t("Please select dates"))}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

  
    return (
      <>
        <Helmet>
          <title>{_t(t("Closing Stock"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-2 mb-lg-0">
                <div className="t-bg-white p-1">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      {
                         loading && (
                           <ProgressBarModal
                             loading = {loading}
                             progress= {progress}
                           />
                         )   
                      }
                      {/* spin loading ends */}
  
                      {/* Loading effect */}
                      {/* {loading === true ? (
                        tableLoading()
                      ) : ( */}
                       <>
                        <div key="smtp-form mt-2">
                          <div className="row gx-2 align-items-center t-pt-2 t-pb-2">
                            <div className="col-12 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize mt-3">
                                    {_t(t("Closing Stock"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-2">
                                <DatePicker
                                  selected={fromEndDate}
                                  onChange={(date) => setFromEndDate(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control"
                                  placeholderText={_t(t("From Date"))}
                                  shouldCloseOnSelect={false}    
                                  maxDate={new Date()}                             
                                />
                                <label className="form-check-label mt-1 mx-1 table-text">
                                  Select date from 01
                                </label>
                            </div>
                            <div className="col-2">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("To Date"))}
                                shouldCloseOnSelect={false}  
                                maxDate={new Date()}                               
                              />
                              <label className="form-check-label mt-1 mx-1 table-text">
                                 Select date to 30,31 and 28,29 for Feb
                              </label>
                            </div>
                          
                            <div className="col-3">
                              <Select
                                options={branchForSearch ? branchForSearch : []}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={closingStock?.branch || ""}
                                isClearable
                                classNamePrefix="select"
                                className="table-text"                               
                                onChange={handleBranch}
                                maxMenuHeight="200px"
                                styles={customStyle}
                                placeholder={_t(t("Select branch")) + ".."}
                              />
                            </div>

                            <div className="col-5 d-flex align-items-start justify-content-end">
                                <button
                                  className="btn btn-primary sm-text text-capitalize rounded mr-2"
                                  onClick={(e) => getClosingStock(e)}
                                >
                                  Closing Inventory
                                </button>
                                <button
                                  className="btn btn-primary sm-text text-capitalize rounded mr-2"
                                  onClick={(e) => getProductionStock(e)}
                                >
                                  Production Update
                                </button>
                                <button
                                  className="btn btn-primary sm-text text-capitalize rounded"
                                  onClick={(e) => getCostingStock(e)}
                                >
                                  F.Cost Update
                                </button>
                            </div>

                          </div>  
                        </div>
                       </>
                    </div>
  
                  </div>
                    
                </div>
                
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default ClosingStock
