import React from 'react'
import { ProgressBar } from "react-bootstrap"; 

const ProgressBarModal = ({loading,progress}) => {
  return (
    <React.Fragment>
        <div className={`${(loading) && "loadingBlur"}`}></div>
        <div className="position-absolute closing_progress">
            <div className={`${(loading) && "lds-roller"}`} style={{top:"13%"}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div className="w-100 position-absolute d-flex align-items-center justify-content-center" style={{top:"65%"}}>
                <ProgressBar className="rounded" style={{width:"75%", height:"1.7rem", background:"#8b8b8b"}} now = {progress} max="100"/>
                <span className="ml-4 xxslg-text text-white position-absolute">{progress}%</span>
            </div>
        </div>
    </React.Fragment>
  )
}

export default ProgressBarModal
