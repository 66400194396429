import React, { useState, useContext, useEffect,useRef } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { ExportToCsv } from 'export-to-csv';
//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  //common
  _t,
  getCookie,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,

  //loading
  modalLoading,
  tableLoading,

  //pagination
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from 'react-icons/md';

//context consumer
import { FoodContext } from "../../../../../contexts/Food";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { SettingsContext } from "../../../../../contexts/Settings";
import Switch from "react-switch";

const PropertyItemCrud = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const propertySlug = useParams();
  //getting context values here

  let [propertyGroupForEdit, setpropertyGroupForEdit] = useState([]);
  let [foodForEdit, setFoodForEdit] = useState([]);
   // csv item
  //  const [generatedReport, setGeneratedReport] = useState(false);
  //  const [itemCsv,setItemCsv] = useState(null);
   const [uploadCsv,setUploadCsv] = useState(null);
   const [uploadCsvModal,setUploadCsvModal] = useState(false);

  let {
    //common
    loading,
    setLoading,
    //property Item
    getPropertyItem,
    propertyItemList,
    setPropertyItemList,
    propertyItemForSearch,
    setPropertyItemForSearch,
    propertyGroupForSearch,
    propertyItemGroup,
    getFood,
    foodForPOS,

    //pagination
    dataPaginating,
  } = useContext(FoodContext);

  let { generalSettings } = useContext(SettingsContext)

  let {
    deptTagForSearch
  } = useContext(RestaurantContext)

  // States hook here
  //new property item
  let [newPropertyItem, setNewPropertyItem] = useState({
    id: null,
    name: "",
    extraPrice: null,
    extraCost: null,
    allow_multi_quantity: false,
    edit: false,
    status: 1,
    editSlug: null,
    uploading: false,
    properties: null,
  });
  const [propertyGrp, setPropertyGrp] = useState({});
  let [priceForItem, setPriceForItem] = useState({});
    //search result
  let [searchedPropertyItem, setSearchedPropertyItem] = useState({
    list: null,
    searched: false,
  });
  const menuWisePrice = useRef(0);
  //const componentRef = useRef();
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: 'Property-item',
  };
  const csvExporter = new ExportToCsv(options);


  //useEffect == componentDidMount
  useEffect(() => {
    console.log("here ", propertyGroupForSearch);
    setLoading(true);
    getPropertyItem(propertySlug.slug);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(()=>{

    if(getSystemSettings(generalSettings, "menu_wise_rate") == 1){
      menuWisePrice.current = 1
     }else{
      menuWisePrice.current = 0
     }

     deptWisePriceStatus()
  },[generalSettings,deptTagForSearch])

  const deptWisePriceStatus = () =>{
    const updatedPriceForItem = {};
         // Iterate through the menu array and add items to updatedPriceForItem
    deptTagForSearch && deptTagForSearch.forEach((menuItem) => {
      updatedPriceForItem[menuItem.id] = {
       extraPrice: "0",
       menu_name: menuItem.name,
       menu_id : menuItem.id,
       status: 1,
     }
    });
    setPriceForItem(updatedPriceForItem)
  }

  useEffect(() => {
    if (propertyGroupForSearch && propertyGroupForSearch.length) {
      const obj = {}
      for(const propertyGroup of propertyGroupForSearch) {
        obj[propertyGroup.id] = propertyGroup
      }
      setPropertyGrp(obj)
    }
  }, [propertyGroupForSearch])

  //set name hook
  const handleSetNewPropertyItem = (e) => {
    setNewPropertyItem({
      ...newPropertyItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleSetPropertyPrice = (e,key) => {
    setPriceForItem({
      ...priceForItem,
      [key]:{
        ...priceForItem[key],
        [e.target.name]: e.target.value
      }
    })
  }


  const handleSetPropertyStatus = (e,key) =>{
    setPriceForItem({
      ...priceForItem,
      [key]:{
        ...priceForItem[key],
        [e.target.name]: parseInt(priceForItem[key].status) === 0 ? 1 : 0,
      }
    })
  }

  const handleSetPropertes = (properties) => {
    console.log("properties", properties);
    setNewPropertyItem({ ...newPropertyItem, properties });
    setpropertyGroupForEdit(properties);
  };

  const handleSetFoodItems = (items) => {
    setNewPropertyItem({ ...newPropertyItem, items });
    setFoodForEdit(items);
  };

  const handleMultiQuantity = () => {
    setNewPropertyItem({
      ...newPropertyItem,
      allow_multi_quantity: !newPropertyItem.allow_multi_quantity,
    });
  };

  const handleStatus = () => {
    setNewPropertyItem({
      ...newPropertyItem,
      status: newPropertyItem.status == 1 ? 0 : 1,
    });
  }

  //Save New property item
  const handleSaveNewPropertyItem = (e) => {
    e.preventDefault();
    setNewPropertyItem({
      ...newPropertyItem,
      uploading: true,
    });
    const propertyItemUrl = BASE_URL + `/settings/new-property-item`;
    let formData = new FormData();
    formData.append("name", newPropertyItem.name);
    if(menuWisePrice.current){
      formData.append("multiPrice", JSON.stringify(priceForItem))
      formData.append("extraPrice", 0)
    }else{
      formData.append("extraPrice", newPropertyItem.extraPrice)
    }
    formData.append("extraCost", newPropertyItem.extraCost);
    formData.append("status", newPropertyItem.status);
    formData.append(
      "allow_multi_quantity",
      newPropertyItem.allow_multi_quantity === false ? 0 : 1
    );
    formData.append("propertyGroupSlug", propertySlug.slug);

    // change work //
    let tempArray = [];
    newPropertyItem?.properties && newPropertyItem.properties.map((pItem) => {
      tempArray.push(pItem.id);
    });
    formData.append("propertiesGroup", tempArray);

    let itemArray = [];
    newPropertyItem?.items && newPropertyItem.items.map((Item) => {
      itemArray.push(Item.id);
    });
    formData.append("items", itemArray);

    return axios
      .post(propertyItemUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewPropertyItem({
          id: null,
          name: "",
          extraPrice: null,
          extraCost: null,
          status: 1,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setpropertyGroupForEdit([]);
        setFoodForEdit([]);
        deptWisePriceStatus()
        setPropertyItemList(res.data[0]);
        setPropertyItemForSearch(res.data[0]);
        setSearchedPropertyItem({
          ...searchedPropertyItem,
          list: res.data[0],
        });
        getFood();
        setLoading(false);
        toast.success(`${_t(t("Property item has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch(() => {
        setLoading(false);
        setNewPropertyItem({
          ...newPropertyItem,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  let selectOptionsProprttyGroup = [];
  let selectOptionsFoodItem = [];
  //set edit true & values
  const handleSetEdit = (id) => {
    let variation = propertyItemForSearch.filter((item) => {
      return item.id === id;
    });

    console.log("variation", variation[0]);

    const propertyArray = JSON.parse(variation[0].property_group_id);

    if (propertyArray.length !== 0) {
      for (var property of propertyArray) {
        for (var i = 0; i < propertyGroupForSearch.length; i++) {
          if (propertyGroupForSearch[i].id == property) {
            selectOptionsProprttyGroup.push(propertyGroupForSearch[i]);
          }
        }
        setpropertyGroupForEdit(selectOptionsProprttyGroup);
      }
    } else {
      setpropertyGroupForEdit([]);
    }

    const itemArray = JSON.parse(variation[0].item_id);

    if (itemArray.length !== 0) {
      for (var item of itemArray) {
        for (var i = 0; i < foodForPOS.length; i++) {
          if (foodForPOS[i].id == item) {
            selectOptionsFoodItem.push(foodForPOS[i]);
          }
        }
        setFoodForEdit(selectOptionsFoodItem);
      }
    } else {
      setFoodForEdit([]);
    }
    const updatedPriceForItem = {};
  
    menuWisePrice.current && variation[0].multi_price && variation[0].multi_price.map((deptTag)=>{
      updatedPriceForItem[deptTag.menu_id] = {
        extraPrice: parseInt(deptTag.extra_price).toFixed(2),
        menu_name: deptTag?.name,
        menu_id : deptTag.menu_id,
        status: deptTag.status,
      }
    });

    setPriceForItem(updatedPriceForItem)

    setNewPropertyItem({
      ...newPropertyItem,
      name: variation[0].name,
      extraPrice: variation[0].extra_price,
      extraCost: variation[0].extra_cost ? variation[0].extra_cost : '0',
      status: variation[0].status ? parseInt(variation[0].status) : 0,
      allow_multi_quantity:
        parseInt(variation[0].allow_multi_quantity) === 1 ? true : false,
      editSlug: variation[0].slug,
      id: variation[0].id,
      properties: selectOptionsProprttyGroup,
      items: selectOptionsFoodItem,
      edit: true,
    });
  };

  const handleUpdateStatus = (item) =>{
    setLoading(true)
    const propertyItemUrl = BASE_URL + `/settings/update-property-item-status`;
    let formData ={
      editSlug: item.slug,
      status: !item.status == true ? 1 : 0
    }
    return axios
      .post(propertyItemUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        
        setPropertyItemList(res.data[0]);
        setPropertyItemForSearch(res.data[0]);
        setSearchedPropertyItem({
          ...searchedPropertyItem,
          list: res.data[0],
        });
        getFood();
        setLoading(false);
        toast.success(`${_t(t("Property item has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  //update property item
  const handleUpdatePropertyItem = (e) => {
    e.preventDefault();
    setNewPropertyItem({
      ...newPropertyItem,
      uploading: true,
    });
    const propertyItemUrl = BASE_URL + `/settings/update-property-item`;
    let formData = new FormData();
    formData.append("name", newPropertyItem.name);
    if(menuWisePrice.current){
      formData.append("multiPrice", JSON.stringify(priceForItem))
      formData.append("extraPrice", 0)
    }else{
      formData.append("extraPrice", newPropertyItem.extraPrice)
    }
    formData.append("extraCost", newPropertyItem.extraCost);
    formData.append("status", newPropertyItem.status);
    formData.append(
      "allow_multi_quantity",
      newPropertyItem.allow_multi_quantity === false ? 0 : 1
    );
    formData.append("editSlug", newPropertyItem.editSlug);
    formData.append("id", newPropertyItem.id);
    formData.append("propertyGroupSlug", propertySlug.slug);

    // change work //
    let tempArray = [];
    newPropertyItem?.properties && newPropertyItem.properties.map((pItem) => {
      tempArray.push(pItem.id);
    });
    formData.append("propertiesGroup", tempArray);

    let itemArray = [];
    newPropertyItem?.items && newPropertyItem.items.map((Item) => {
      itemArray.push(Item.id);
    });
    formData.append("items", itemArray);

    return axios
      .post(propertyItemUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewPropertyItem({
          id: null,
          name: "",
          extraPrice: null,
          extraCost: null,
          status: 1,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        deptWisePriceStatus()
        setPropertyItemList(res.data[0]);
        setPropertyItemForSearch(res.data[0]);
        setSearchedPropertyItem({
          ...searchedPropertyItem,
          list: res.data[0],
        });
        getFood();
        setLoading(false);
        toast.success(`${_t(t("Property item has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewPropertyItem({
          ...newPropertyItem,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //search property item here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedPropertyItem({ ...searchedPropertyItem, searched: false });
    } else {
      let searchedList = propertyItemForSearch.filter((item) => {
        let lowerCaseItemName = item.name.toLowerCase();
        let lowerCaseItemExtraPrice = item.extra_price.toLowerCase();
        let lowerCaseItemExtraCost = (item.extra_cost ? item.extra_cost : '0').toLowerCase();
        let propGroupName = item.property_group_name.toLowerCase();
        return (
          lowerCaseItemName.includes(searchInput) ||
          propGroupName.includes(searchInput) ||
          lowerCaseItemExtraPrice.includes(searchInput) ||
          lowerCaseItemExtraCost.includes(searchInput)
        );
      });
      setSearchedPropertyItem({
        ...searchedPropertyItem,
        list: searchedList,
        searched: true,
      });
    }
  };

  
  const handlePropertyCsv = () =>{
    setLoading(true);
    const url = BASE_URL + `/settings/get-property-item-for-csv`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // setItemCsv(res.data)
        // setGeneratedReport(true);
        // componentRef.current.handleDownload();
        csvExporter.generateCsv(res.data);
        setLoading(false);
        
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  const handleOnChange = (e) => {
    if (e.target.files[0]) {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0]);
      fileReader.onload = (e) => {
        const contents = e.target.result;
        let fileUpload = [];
        let missPrice = "";
        if(isCSV(contents)){
          const startIndex = 1; // Start index
          const lines = contents.trim().split('\n');
          const portion = lines.slice(startIndex);
          const parsedData = portion.map((line)=>{
            const values = line.split(',');
            if(values[3] === null || values[3] === ""){
              missPrice += values[2] + ", "
            }
            fileUpload.push({
              id : values[0]?.replace(/["/\r\n]/g, ''),
              property_group_name : values[1]?.replace(/["/\r\n]/g, ''),
              name : values[2]?.replace(/["/\r\n]/g, ''),
              extra_price : values[3]?.replace(/["/\r\n]/g, ''),
              extra_cost : values[4]?.replace(/["/\r\n]/g, ''),
            })
          })

        }else {
        
          if (document.getElementById('uploadFile')) {
            document.getElementById('uploadFile').value = '';
          }
          setUploadCsv(null)
          setUploadCsvModal(false)
          toast.error("Please upload only property item csv file", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }); 
          // const startIndex = 2; // Start index
          // const lines = contents.split('<tr>');
          // const portion = lines.slice(startIndex);
          // const parsedData = portion.map((line) => {
          //   // Extract the content from the <td> element
          //   const tdContent = line.match(/<td[^>]*>(.*?)<\/td>/g);
          //   if(tdContent){
          //   const divId = document.createElement('div');
          //   divId.innerHTML = tdContent[0];
          //   const divPrpGrp = document.createElement('div');
          //   divPrpGrp.innerHTML = tdContent[1]; 
          //   const divName = document.createElement('div');
          //   divName.innerHTML = tdContent[2]; 
          //   const divPrice = document.createElement('div');
          //   divPrice.innerHTML = tdContent[3];
          //   const divCostPrice = document.createElement('div');
          //   divCostPrice.innerHTML = tdContent[4];  
          //   if(divPrice.textContent === null || divPrice.textContent === ""){
          //     missPrice += divName.textContent + ", ";
          //   }
          //   fileUpload.push({
          //     id : divId.textContent,
          //     food_group_name : divPrpGrp.textContent,
          //     name : divName.textContent,
          //     extra_price : divPrice.textContent,
          //     extra_cost : divCostPrice.textContent,
          //   })
          // }
          // });
        } 
        if(missPrice !== "" && missPrice !== null){
          toast.error("Please add price in " + missPrice, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
        setUploadCsv(fileUpload);
      
      };
      function isCSV(contents) {
        // Basic check if it's a CSV file by checking if it contains a comma
        const rows = contents.split('\n');

          // Extract the header row (assuming it's the first row)
          const headerRow = rows[0];

          // Split the header row into individual header values
          const headers = headerRow.split(',');
        // Basic check if it's a CSV file by checking if it contains a comma
        return contents.includes(',') && contents.includes('\n') && (headers.length === 5);
      }
     
   }; 
  }
 
  const handlePropertyCsvSubmit = (e) => {
    e.preventDefault();
    const url = BASE_URL +"/settings/update-property-item-for-csv";
    let formData = {
      items : uploadCsv
    }
    
    if(!uploadCsv || uploadCsv.length === 0){
      toast.error(`${_t(t("Please upload file"))}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      return false
    }
    if (document.getElementById('uploadFile')) {
       document.getElementById('uploadFile').value = '';
     }
    setLoading(true);
    return axios
      .post(url,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPropertyItemList(res.data[0]);
        setPropertyItemForSearch(res.data[0]);
        setSearchedPropertyItem({
          ...searchedPropertyItem,
          list: res.data[0],
        });
        setUploadCsv(null);
        setUploadCsvModal(false)
        setLoading(false);
        toast.success(`${_t(t("Item file upload successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        
      })
      .catch(() => {
        setLoading(false);
        setUploadCsv(null);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  //delete confirmation modal of property item
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeletePropertyItem(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
 
  //delete variation here
  const handleDeletePropertyItem = (slug) => {
    setLoading(true);
    const propertyItemUrl = BASE_URL + `/settings/delete-property-item/${slug}`;
    return axios
      .get(propertyItemUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPropertyItemList(res.data[0]);
        setPropertyItemForSearch(res.data[0]);
        setSearchedPropertyItem({
          ...searchedPropertyItem,
          list: res.data[0],
        });
        getFood();
        setLoading(false);
        toast.success(
          `${_t(t("Property item has been deleted successfully"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Property Items"))}</title>
      </Helmet>
     
          {/*Reason Modal*/}
         <div
          className={`modal fade ${
            uploadCsvModal !== false ? "showing" : ""
          }`}
          id="addReason"
          aria-hidden="true"
         >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header align-items-center">
                <div className="fk-sm-card__content"></div>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (  document.getElementById('uploadFile')) {
                      document.getElementById('uploadFile').value = '';
                    }
                    setUploadCsv(null)
                    setUploadCsvModal(false)
                  }}
                ></button>
              </div>
              <div className="modal-body">
                {/* show form or show saving loading */}
                <div key="fragment-food-group-1">
                  <form autoComplete="off" onSubmit={handlePropertyCsvSubmit}>
                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Upload File"))}{" "}
                        <small style={{ color: "red" }}>*</small>
                      </label>
                      <br/>
                      <input
                        type={"file"}
                        id="uploadFile"
                        name="uploadFile"
                        accept={".csv"}
                        onChange={handleOnChange}
                      />
                      </div>
                      <button
                      type="submit"
                      className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4 rounded"
                      >
                        {_t(t("Submit"))}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>


                {/* Print Button */}
     {/* <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {"\
          @page { size: landscape; }\
        "}
        </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                   {itemCsv &&
                    itemCsv !== null &&
                    generatedReport === true && (
                      <div>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                          <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="table-to-xls"
                          >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Property Id"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Property Group Name"))}
                                </th>
                           
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Property Name"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Price"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Cost Price"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                               {
                                itemCsv && itemCsv.map((item)=>{
                                  return (
                                    <tr>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      contentEditable={false}
                                      readOnly
                                      >
                                        {item.id}
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      contentEditable={false}
                                      readOnly
                                      >
                                        {item.property_group_name}
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      contentEditable={false}
                                      readOnly
                                      >
                                        {item.name}
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.extra_price}
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.extra_cost}
                                      </td>
                                    </tr>
                                  )
                                })
                               }
                            </tbody>
                            <tfoot style={{ border: "0px" }}>
                              <tr style={{ border: "0px" }}>
                                <th
                                  colspan="7"
                                  className="text-center"
                                  style={{ border: "0px" }}
                                >
                                  <h5 className="mt-3">
                                    Powered by indolj.pk
                                  </h5>
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                      </div>
                    )
                   }
                </div>
              </div>
            </div>
          </div>
        </div>
                  </div>     */}   

      {/* Add modal */}
      <div className="modal fade" id="addvariation" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newPropertyItem.edit
                    ? _t(t("Add new property item"))
                    : _t(t("Update property item"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                data-dismiss="modal"
                aria-label="Close"
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newPropertyItem.uploading === false ? (
                <div key="fragment-property-item-1">
                  <form
                    onSubmit={
                      !newPropertyItem.edit
                        ? handleSaveNewPropertyItem
                        : handleUpdatePropertyItem
                    }
                    autoComplete="off"
                  >
                   <div  className="row">
                    <div className="col-6">
                      <label htmlFor="name" className="form-label">
                        {_t(t("Name"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="e.g. Egg, Cheese, Mild, Spicy"
                        value={newPropertyItem.name || ""}
                        required
                        onChange={handleSetNewPropertyItem}
                      />
                    </div>
                    <div className="form-group col-6">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Link with item"))}
                        </label>
                      </div>
                      <Select
                        value={foodForEdit}
                        options={foodForPOS}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        maxMenuHeight="200px"
                        onChange={handleSetFoodItems}
                        placeholder={_t(t("Please select Items")) + ".."}
                      />
                    </div>
                   </div>
                   {
                      menuWisePrice.current ? (
                        <div className="row">
                        {
                          deptTagForSearch && deptTagForSearch?.map((deptTag)=>{
                           if(newPropertyItem?.edit ? Object.keys(priceForItem).includes(deptTag.id.toString()) : true){
                            return (
                               <div className="col-4 mt-4">
                               <label htmlFor="extraPrice" className="form-label">
                                {_t(t("Price"))}{" "}
                                <small className="text-danger">* </small>
                                <small className="text-secondary table-text mr-2">
                                  {deptTag.name}{" "}
                                </small>
                                  <input
                                  type="checkbox"
                                  className="form-check-input pointer-cursor"
                                  id="status"
                                  name="status"
                                  onChange={(e) => {
                                    handleSetPropertyStatus(e,deptTag?.id)
                                  }}
                                  checked={
                                    parseInt(priceForItem && priceForItem[deptTag?.id] && priceForItem[deptTag?.id].status) === 1
                                  }
                                 />
                                </label>
                                <input
                                  type="number"
                                  step="0.01"
                                  min="0"
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  className="form-control"
                                  id="extraPrice"
                                  name="extraPrice"
                                  placeholder="Type price of this item"
                                  value={priceForItem && priceForItem[deptTag?.id]?.extraPrice}
                                  required
                                  onChange={(e)=>handleSetPropertyPrice(e,deptTag?.id)}
                                />
                              </div>
                              )
                             }
                            })
                          }
                          </div>
                        
                      ) : (
                        <div className="row m-0 mt-4 justify-content-between">
                        <div className="col-6 pl-0">
                          <label htmlFor="extraPrice" className="form-label">
                            {_t(t("Price"))}{" "}
                            <small className="text-danger">* </small>
                          </label>
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            className="form-control"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            id="extraPrice"
                            name="extraPrice"
                            placeholder="e.g. Type price of this item"
                            value={newPropertyItem?.extraPrice || ""}
                            required
                            onChange={handleSetNewPropertyItem}
                          />
                        </div>
                        </div>
                      )
                   }
                   
                  <div className="row">
                   <div className="form-group mt-4 col-6">
                   <label htmlFor="extraCost" className="form-label">
                     {_t(t("Cost"))} {" "}
                     <small className="text-danger">* </small>
                   </label>
                   <input
                     type="number"
                     step="0.01"
                     min="0"
                     onKeyDown={(evt) =>
                       ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                       evt.preventDefault()
                     }
                     className="form-control"
                     id="extraCost"
                     name="extraCost"
                     placeholder="e.g. Type price of this item"
                     value={newPropertyItem.extraCost || ""}
                     required
                     onChange={handleSetNewPropertyItem}
                     />
                   </div>
                    <div className="form-group mt-4 col-6">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Add properties group"))}
                        </label>
                      </div>
                      <Select
                        value={propertyGroupForEdit}
                        options={propertyGroupForSearch}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        maxMenuHeight="200px"
                        onChange={handleSetPropertes}
                        placeholder={
                          _t(t("Please select property groups")) + ".."
                        }
                      />
                    </div>
                  </div>
                  <div className="row ml-2">
                    <div className="form-check mt-4 col-6">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="has_multiple_quantity"
                        checked={newPropertyItem.allow_multi_quantity}
                        onChange={handleMultiQuantity}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="has_multiple_quantity"
                      >
                        {_t(t("Allow multiple quantity?"))}
                      </label>
                    </div>

                    <div className="form-check mt-4 p-0 col-6">
                      <Switch
                        name="status"
                        height={22}
                        width={44}
                        offColor="#ee5253"
                        className="float-left"
                        checked={newPropertyItem.status}
                        onChange={handleStatus}
                      />
                      <label
                        className="form-check-label ml-2"
                        htmlFor="status"
                      >
                        {_t(t("Status"))}
                      </label>
                    </div>
                   </div>
                    <div className="mt-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-3">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newPropertyItem.edit
                              ? _t(t("Save"))
                              : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newPropertyItem.edit
                            ? _t(t("Save"))
                            : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newPropertyItem.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0 d-flex justify-content-between">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedPropertyItem.searched
                                    ? [
                                        `${
                                          propertyItemGroup
                                            ? propertyItemGroup.name
                                            : ""
                                        } ${_t(t("Property List"))}`,
                                      ]
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                            <div>
                            <button title="Download property items in excel" className="btn btn-success btn-sm text-uppercase sm-text mr-2 rounded" onClick={handlePropertyCsv} >Export Csv</button>
                            <button title="upload property items in excel" className="btn btn-primary btn-sm text-uppercase sm-text mr-2 rounded" onClick={()=>setUploadCsvModal(true)}>Import Csv</button>
                           </div>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addvariation"
                                  onClick={() => {
                                    setNewPropertyItem({
                                      ...newPropertyItem,
                                      id: null,
                                      name: "",
                                      extraPrice: null,
                                      extraCost: null,
                                      allow_multi_quantity: false,
                                      edit: false,
                                      status: 1,
                                      editSlug: null,
                                      uploading: false,
                                      properties: null,
                                    });
                                    setpropertyGroupForEdit([]);
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Property Group"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Price"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Status"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                            {console.log("abcd44",propertyItemList)}
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedPropertyItem.searched
                                ? [
                                    propertyItemList && [
                                      propertyItemList.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        propertyItemList.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index + 1}
                                              </th>

                                              <td className="xsm-text align-middle text-center">
                                                {item.name}
                                              </td>
                                              <td className="xsm-text align-middle text-center">
                                              {item?.property_group_name && item?.property_group_id ?
                                                JSON.parse(item.property_group_id).map((menuId,i,menus) => (
                                                    <>
                                                      {propertyGrp[menuId] ? 
                                                      <span>
                                                        {propertyGrp[menuId].name}
                                                        {i!== menus.length-1 ? ', ': ''}
                                                      </span>
                                                  : ''}
                                                    </>
                                                  ))
                                                  : '-'}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(item.extra_price)}
                                                {currencySymbolRight()}
                                              </td>

                                              <td className="xsm-text align-middle text-center text-uppercase font-weight-bold">
                                                <Switch
                                                checked={item?.status}
                                                name="status"
                                                onChange={(e) => {
                                                  handleUpdateStatus(item)
                                                
                                                }}
                                                height={22}
                                                width={44}
                                                offColor="#ee5253"
                                                />
                                                {/*item.status
                                                  ? <span className="text-success">{_t(t("On"))}</span>
                                                  : <span className="text-danger">{_t(t("Off"))}</span>
                                                      */}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {/* <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        handleSetEdit(item.id)
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#addvariation"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.slug
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div> */}
                                                <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() =>
                                                    handleSetEdit(item.id)
                                                  }
                                                  data-toggle="modal"
                                                  data-target="#addvariation"                                               
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.slug)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedPropertyItem && [
                                      searchedPropertyItem.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedPropertyItem.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index + 1}
                                                </th>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.name}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.property_group_name}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {currencySymbolLeft()}
                                                  {formatPrice(
                                                    item.extra_price
                                                  )}
                                                  {currencySymbolRight()}
                                                </td>

                                                <td className="xsm-text align-middle text-center text-uppercase font-weight-bold">
                                                  <Switch
                                                  checked={item?.status}
                                                  name="status"
                                                  onChange={(e) => {
                                                    handleUpdateStatus(item)
                                                  
                                                  }}
                                                  height={22}
                                                  width={44}
                                                  offColor="#ee5253"
                                                  />
                                                  {/*item.status
                                                    ? <span className="text-success">{_t(t("On"))}</span>
                                                    : <span className="text-danger">{_t(t("Off"))}</span>
                                                  */}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/* <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() =>
                                                          handleSetEdit(
                                                            item.id
                                                          )
                                                        }
                                                        data-toggle="modal"
                                                        data-target="#addvariation"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.slug
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button>
                                                    </div>
                                                  </div> */}
                                                  <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() =>
                                                    handleSetEdit(item.id)
                                                  }
                                                  data-toggle="modal"
                                                  data-target="#addvariation"                                               
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.slug)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newPropertyItem.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedPropertyItem.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              <div style={{ height: "33px" }}></div>
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {_t(t("Showing"))}{" "}
                                    {propertyItemForSearch &&
                                      propertyItemForSearch.length}{" "}
                                    {_t(t("of"))}{" "}
                                    {propertyItemForSearch &&
                                      propertyItemForSearch.length}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedPropertyItem({
                                      ...searchedPropertyItem,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedPropertyItem,
                                    propertyItemForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default PropertyItemCrud;
